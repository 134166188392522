import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';

import { receiveTickets } from '@ttstr/actions';
import { Container, LoadingSpinner, Masonry, useIntl, ContentrArea } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import Maki2020 from './Maki2020';

const TicketListing: React.FC = () => {
  const { t } = useTranslation();
  const { loading, tickets } = useShallowEqualSelector(mapStateToProps);
  const { receiveTickets } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveTickets();
  }, [language]);

  return (
    <article className="ticket-listing">
      <Helmet>
        <title>{t(`TICKETLISTING.TITLE`)}</title>
        <body className="ticket-listing-page inverse-navbar" />
      </Helmet>

      <Container className="list-page py-5">
        {/* <h1 className="text-center mb-4">{t(`TICKETLISTING.TITLE`)}</h1> */}

        {/* <img
          src={require('./assets/images/kk-header.png')}
          alt="Bandmitglieder liegen lachend auf einem weissen Boden mit bunten Bällen und Luftballons"
          className="w-100 img-fluid mb-5"
        /> */}

        <ContentrArea id="before-listing" />

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.TICKETS`)} />
        ) : tickets.length === 0 ? ( // if no tickets
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show tickets
          <Masonry id="ticket-listing" products={tickets} type={Maki2020} filters={['fulltext']} showSubtitle showTags/>
        )}

        <ContentrArea id="after-listing" />
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { tickets, loading } = state.Tickets;
  return {
    tickets,
    loading,
  };
};

const mapDispatchToProps = {
  receiveTickets,
};

export default React.memo(TicketListing);
