import classNames from 'classnames';

import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from 'reactstrap/lib/Button';
import Collapse from 'reactstrap/lib/Collapse';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import CardTitle from 'reactstrap/lib/CardTitle';

import Currency from '@ttstr/components/Intl/Currency';
import DateComponent from '@ttstr/components/Intl/DateComponent';
import { isAbsoluteURL } from '@ttstr/utils';

import CardImgOverlay from 'reactstrap/lib/CardImgOverlay';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { useProductDetails } from '@ttstr/components/ProductDetail';
import { useProductDetailLink } from '@ttstr/components/ProductDetail/ProductDetailLinkContext';
import Time from '@ttstr/components/Intl/Time';
import PreviewImage from '@ttstr/components/Sushi/PreviewImage';
import { SushiProps } from '@ttstr/components/Sushi/Sushi';

type Props = Readonly<SushiProps>;

const PLACEHOLDER_IMAGE =
  'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22240%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20240%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_1664f286b32%20text%20%7B%20fill%3Argba(255%2C255%2C255%2C.75)%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A12pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_1664f286b32%22%3E%3Crect%20width%3D%22240%22%20height%3D%22180%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2289.2578125%22%20y%3D%2295.1%22%3E240x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E';

const Maki2020: React.FC<Props> = ({
  product,
  showBrand,
  showCategories,
  showLocation = true,
  showMerchant,
  showSubtitle,
  showSupertitle,
  showTags, // TODO
  showFormerPrice,
  className,
  imageClassName,
}) => {
  const {
    categoriesReceived,
    categories,
    brandsReceived,
    brands,
    locationsReceived,
    locations,
    merchantsReceived,
    merchants,
    tagsReceived,
    tags,
  } = useProductDetails();
  const productDetailLink = useProductDetailLink();
  const currentBrands = brandsReceived ? product.brand_ids.map((id) => brands[id]) : null;
  const currentBrand = currentBrands?.[0];
  const currentCategories = categoriesReceived
    ? product.category_ids.map((id) => categories[id] ?? null).filter((c) => c !== null)
    : null;
  const currentCategory = currentCategories?.[0];
  const currentLocation = locationsReceived ? locations[product.location_id] : null;
  const currentMerchant = merchantsReceived ? merchants[product.merchant_id] : null;
  const currentTags = tagsReceived ? product.tag_ids.map((id) => tags[id]) : null;

  const { t } = useTranslation();
  const makiRef = React.useRef<HTMLAnchorElement>();
  const isAbsolute = product.redirect && isAbsoluteURL(product.redirect);
  const [showActiveMenu, setShowActiveMenu] = React.useState(false);

  React.useEffect(() => {
    const show = (e: Event) => {
      if (e.target !== makiRef.current) return;
      setShowActiveMenu(true);
    };
    const hide = (e: Event) => {
      if (e.target !== makiRef.current) return;
      setShowActiveMenu(false);
    };
    makiRef.current.addEventListener('pointerenter', show, { capture: true, passive: true });
    makiRef.current.addEventListener('pointerleave', hide, { capture: true, passive: true });
    makiRef.current.addEventListener('focus', show, { capture: true, passive: true });
    makiRef.current.addEventListener('blur', hide, { capture: true, passive: true });
    return () => {
      if (!makiRef.current) return;
      makiRef.current.removeEventListener('pointerenter', show);
      makiRef.current.removeEventListener('pointerleave', hide);
      makiRef.current.removeEventListener('focus', show);
      makiRef.current.removeEventListener('blur', hide);
    };
  }, []);

  const price = React.useMemo(() => {
    return Boolean(product.min_price && product.max_price) && product.min_price !== product.max_price ? (
      <span className="price" itemProp="offers" itemType="http://schema.org/AggregateOffer" itemScope>
        <meta itemProp="lowPrice" content={String(product.min_price)} />
        <meta itemProp="highPrice" content={String(product.max_price)} />
        <meta itemProp="priceCurrency" content="EUR" />
        {product.valid_end_on && <meta itemProp="priceValidUntil" content={product.valid_end_on.toISOString()} />}
        <meta
          itemProp="availability"
          content={product.sold_out_status ? 'https://schema.org/SoldOut' : 'https://schema.org/InStock'}
        />
        <link itemProp="url" href={productDetailLink(product)} />
        <Currency value={product.min_price} />
        {' - '}
        <Currency value={product.max_price} />
      </span>
    ) : (
      <span className="price" itemProp="offers" itemType="http://schema.org/Offer" itemScope>
        <meta itemProp="price" content={String(product.min_price)} />
        <meta itemProp="priceCurrency" content="EUR" />
        <meta
          itemProp="availability"
          content={product.sold_out_status ? 'https://schema.org/SoldOut' : 'https://schema.org/InStock'}
        />
        <link itemProp="url" href={productDetailLink(product)} />
        <Currency value={product.min_price} />
      </span>
    );
  }, [product, productDetailLink]);

  return (
    <Card
      innerRef={makiRef}
      id={`product-${product.id}`}
      tag={isAbsolute ? 'a' : Link}
      {...(isAbsolute
        ? { href: product.redirect, rel: 'noopener noreferrer', target: '_self' }
        : { to: productDetailLink(product) })}
      className={classNames(
        'maki-2020',
        className,
        currentBrands && currentBrands.map((c) => `brand-${c.sid} brand-id-${c.id}`),
        currentCategories && currentCategories.map((c) => `category-${c.sid} category-id-${c.id}`),
        currentLocation && `location-${currentLocation.sid} location-id-${currentLocation.id}`,
        currentMerchant && `merchant-${currentMerchant.sid} merchant-id-${currentMerchant.id}`,
        currentTags && currentTags.map((c) => `tag-id-${c.id}`)
      )}
      itemProp="url"
    >
      <meta itemProp="sku" content={String(product.id)} />
      <link itemProp="image" href={product.image.url} />
      <div className={classNames('maki-img-container', imageClassName)}>
        <PreviewImage
          className="card-img-top maki-img"
          previewSrc={product.image_thumb}
          src={product.image.url || PLACEHOLDER_IMAGE}
          alt={product.title}
          itemProp="image"
        />
        {product.free_image_1?.url && (
          <PreviewImage
            className="card-img-top maki-img maki-img-hover"
            previewSrc={product.image_thumb}
            src={product.free_image_1.url || PLACEHOLDER_IMAGE}
            alt={product.title}
            itemProp="image"
          />
        )}
      </div>
      {(product.valid_start_on || (showLocation && currentLocation)) && (
        <div className="overlay">
          {product.valid_start_on && (
            <strong className="start_on">
              <DateComponent value={product.valid_start_on} />
            </strong>
          )}
          {showLocation && currentLocation && (
            <span className="location">
              <i className="fal fa-location-arrow mr-2" />
              {currentLocation.city}
            </span>
          )}
        </div>
      )}
      <CardImgOverlay>
        <CardBody>
          {product.sold_out_status ? (
            <div className={`overlay bg-danger sold-out-status sold-out-status_${product.sold_out_status}`}>
              {t(`PRODUCT.SOLD_OUT_STATUS.${product.sold_out_status}`)}
            </div>
          ) : (
            showTags &&
            currentTags && (
              <div className={`overlay tag-container`}>
                <span className="tags">
                  {currentTags.map((tag) =>
                    tag.icon ? (
                      <span key={tag.id} className={`tag tag-${tag.id} icon-tag`}>
                        <i className={`fal fa-${tag.icon} icon-tag--icon`} />
                        <span className="sr-only">{tag.title}</span>
                      </span>
                    ) : (
                      <span
                        key={tag.id}
                        className={`tag tag-${tag.id}`}
                        style={{
                          color: tag.text_color,
                          backgroundColor: tag.bg_color,
                        }}
                      >
                        {tag.title}
                      </span>
                    )
                  )}
                </span>
              </div>
            )
          )}
          <CardTitle tag="h2">
            {showSupertitle && product.supertitle && <small className="super-title">{product.supertitle}</small>}
            <span className="title" itemProp="name">
              {product.title}
            </span>
            {showSubtitle && <small className="sub-title">{product.subtitle || <>&nbsp;</>}</small>}
            {product.valid_start_on && (
              <strong className="start_on">
                <DateComponent value={product.valid_start_on} />
              </strong>
            )}
            {Boolean(currentCategory) && (
              <span className="location">
                {/* <i className="fal fa-location-arrow mr-2" /> */}
                {' / '} {currentCategory.title}
              </span>
            )}

            <small className="prices">
              {showFormerPrice && product.former_price && (
                <span className="former-price mr-2 text-decoration-line-through text-danger">
                  <Currency value={product.former_price} />
                </span>
              )}
              {' / '} {price}
            </small>

            {showCategories && currentCategories && (
              <span className="categories">
                {currentCategories.map((category) => (
                  <span key={category.id} className="badge badge-light category">
                    {category.title}
                  </span>
                ))}
              </span>
            )}
          </CardTitle>
          {showBrand && currentBrand && (
            <span className="brand">
              <img
                src={currentBrand.image.url}
                className="img-fluid product-thumb rounded-circle"
                alt={currentBrand.description}
                loading="lazy"
              />{' '}
              {currentBrand.title}
            </span>
          )}
          <Collapse isOpen={showActiveMenu} className="active-menu">
            {product.valid_start_on && (
              <Row>
                <Col>
                  <small className="start_on">
                    <i className="fas fa-calendar mr-2" />
                    <DateComponent value={product.valid_start_on} />
                  </small>
                </Col>
                <Col>
                  <small className="start_on">
                    <i className="fas fa-clock mr-2" />
                    {product.time_begin}
                  </small>
                </Col>
              </Row>
            )}
            {showLocation && currentLocation && (
              <p>
                <small className="location">
                  <i className="fas fa-location-arrow mr-2" />
                  {currentLocation.title}, {currentLocation.zip_code} {currentLocation.city}
                </small>
              </p>
            )}
            <Button type="button" size="lg" color="primary" block tabIndex={-1}>
              {product.type === 'Ticket' ? (
                <span>
                  <i className="fal fa-ticket mr-2" />
                  {t('BUY.TICKET')}
                </span>
              ) : product.type === 'Package' ? (
                <span>
                  <i className="fal fa-box-alt mr-2" />
                  {t('BUY.PACKAGE')}
                </span>
              ) : (
                <span>
                  <i className="fal fa-tshirt mr-2" />
                  {t('BUY.PRODUCT')}
                </span>
              )}
            </Button>
          </Collapse>
        </CardBody>
      </CardImgOverlay>
    </Card>
  );
};

export default React.memo(Maki2020);
